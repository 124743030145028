@import "node_modules/bulmaswatch/lux/variables";

@import "./custom-bulma-vars.scss";
@import "bulma";
@import "bulma-switch/dist/css/bulma-switch.sass";
@import "bulma-tooltip";
@import "bulma-checkradio/dist/css/bulma-checkradio.sass";
@import "bulma-divider/dist/css/bulma-divider.sass";
@import "bulma-pageloader/dist/css/bulma-pageloader.sass";
@import "bulma-ribbon/dist/css/bulma-ribbon.sass";
@import "node_modules/bulmaswatch/lux/overrides";
@import "./lux-overrides.scss";
//@import "./firebaseui-styling.global.scss";

body {
  @extend .has-background-primary;
}

main {
  @extend .has-background-white-ter;
}

dt {
  font-weight: bold;
}

footer {
  .logo {
    max-width: 170px;
  }
}

.trade-item-detail {
  margin-bottom: 1rem;
}

.form-subtitle {
  margin-top: 2rem;
}

.hero {
  &.has-background {
    position: relative;
    overflow: hidden;
  }

  &-background {
    position: absolute;
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;

    &.is-transparent {
      opacity: 0.3;
    }
  }
}

.has-img-background {
  background: linear-gradient(
      to bottom,
      rgba(34, 34, 34, 0.99) 40%,
      rgba(34, 34, 34, 0.7) 100%
    ),
    url("../images/ricardo-gomez-angel-9AdeEdYB2yk-unsplash-lores.jpg")
      no-repeat;
  background-size: cover;
}

.has-img-background-footer {
  background: linear-gradient(
      to bottom,
      rgba(34, 34, 34, 0.99) 25%,
      rgba(34, 34, 34, 0.9) 75%,
      rgba(34, 34, 34, 0.7) 100%
    ),
    url("../images/ricardo-gomez-angel-9AdeEdYB2yk-unsplash-lores.jpg")
      no-repeat;
  background-size: cover;
}

.has-img-background-full {
  background: linear-gradient(
      to bottom,
      rgba(34, 34, 34, 0.99) 20%,
      rgba(34, 34, 34, 0.7) 100%
    ),
    url("../images/ricardo-gomez-angel-9AdeEdYB2yk-unsplash-lores.jpg")
      no-repeat;
  background-size: cover;
}

.has-img-background-btc {
  background: linear-gradient(
      to bottom,
      rgba(34, 34, 34, 1) 30%,
      rgba(34, 34, 34, 0.7) 100%
    ),
    url("../images/aleksi-raisa-DCCt1CQT8Os-unsplash-cropped.jpg");
  background-size: cover;
}

.home-logo {
  max-width: 420px;
  margin-bottom: 1rem;

  @media screen and (max-width: 600px) {
    max-width: 300px;
  }
}

.logo-in-page {
  width: 70px;
}

.no-bottom-padding {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.photo-credit {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

// react-datetime overrides
.modal-card-body .rdtPicker {
  width: auto !important;
}
th.rdtSwitch {
  text-align: center !important;
}
