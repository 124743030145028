// $primary: #243b53;
// $link: $primary;
// $info: #2bb0ed;
// $success: #199473;
// $warning: #f7c948;
// $danger: #ef4e4e;
// $light: #f0f4f8;
// $dark: #243b53;
// $text: #627d98;

// $body-background-color: #f0f4f8;

$body-size: 16px;
$box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
  0 0 0 1px rgba(10, 10, 10, 0.02);
$dimensions: 16 24 32 48 64 96 128 256 512;
$navbar-item-img-max-height: 1.5rem;
